export const styledContent = (content) => {
	if (content) {
		content = formatText(content, /\*\*(.*?)\*\*/g)
	}
	return content
}

const formatText = (text, regex) => {
	let values = text.match(regex)

	if (!values || !values.length)
		return text.replace("---", ":", true)

	values = values.map(v => v.replace(/\*\*/g, "", true))

	const result = []

	text.split(regex)
		.forEach((current, i) => {
			if (!i) {
				result.push(current.replace("---", ":", true))
			} else {
				result.push(
					values.includes(current)  ?
						<strong key={i + current}>
							{current.replace("---", ":", true)}
						</strong>
						: current.replace("---", ":", true)
				)
			}
		}
	)
	return result
}
