
export default function replace() {
	let [ target, ...variables ] = arguments

	const matches = target.match(/\[[^\]]+\]/g)

	matches.forEach((match, index) => {
		if (!variables[index]) {
			return
		}

		target = target.replace(match, variables[index])
	})

	return target
}
