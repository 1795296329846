import { gql } from "apollo-boost"

export const FETCH_MAIN_CATEGORIES = gql`
	query FetchMainCategories {
		mainCategories {
			key,
			foreignkey
		}
	}
`

export const FETCH_ADMIN_PAGE = gql`
	query FetchAdminPage($route: String!) {
		adminPage(route: $route) {
			route,
			title {
				langCode,
				value
			},
			description{
				langCode,
				value
			}
		}
	}
`
export const FETCH_CATEGORY_ID = gql`
	query FetchCategory($key: String, $adminDetails: Boolean) {
		category(key: $key, adminDetails: $adminDetails) {
			foreignkey,
			custom
		}
	}
`

export const FETCH_ADMIN_CATEGORY = gql`
	query FetchAdminCategory($id: String!) {
		category(key: $id) {
			key,
			custom
		}
	}
`

export const FETCH_ADMIN_LISTS = gql`
	query FetchListsIds {
		lists {
			foreignkey,
			custom
		}
	}
`

export const FETCH_ADMIN_PROFILE = gql`
	query getAdmin {
		me {
			firstname,
			lastname,
			email
		}
	}
`

export const FETCH_PREHOME = gql`
	query FetchPreHome {
		prehome {
			id
			standard
			duration
			times
			images {
				desktop
				tablet { portrait	landscape	}
				mobile { portrait	landscape	}
			}
			buttons {
				position
				button1 {	label	title	link }
				button2 {	label	title	link }
			}
		}
	}
`
export const FETCH_DIRECTOR_DETAILS = gql`
	query FetchDirectorDetails($id: Int!) {
		director(id: $id) {
			id,
			name,
			description { lang, langCode, value },
			description_short { lang, langCode, value },
			link_rewrite { lang, langCode, value }
		}
	}
`

export const FETCH_PRODUCT_DETAILS = gql`
  query FetchProductDetails($id: Int!) {
		product(id: $id) {
			id,
			description_short { lang, langCode, value },
			link_rewrite { lang, langCode, value },
			description { lang, langCode, value },
			name { lang, langCode, value },
			image,
			videos {
				id
				name
				filename
				thumbnail
			}
			directors {
				id
				name
				image
			}
			year
			origin
			classification
			availability
			duration
			original_title
		}
	}
`

export const FETCH_FILES_BY_NAME = gql`
	query FetchFilesByName($name: String) {
		files(name: $name) {
			id,
			title,
			filename
		}
	}
`

export const FETCH_STRIPE_CUSTOMER = gql`
	query FetchCustomer($email: String!) {
		stripeCustomer(email: $email) {
			id
		}
	}
`

export const FETCH_CARDS = gql`
	query FetchCards($email: String!) {
		cards(email: $email) {
			id
			name
			token
			brand
			exp_month
			exp_year
			last4
		}
	}
`

export const FETCH_FILM_NOTIFICATIONS = gql`
	query fetchFilmsNotifications($user: Int!) {
		filmNotifications(user: $user)
	}
`

export const FETCH_GIFT = gql`
	query FetchGift($token: String!) {
		gift(token: $token) {
			id
		}
	}
`

export const FETCH_RENEWALS = gql`
	query FetchRenewals($user: Int!) {
		renewal(user: $user) {
			renewal_date
			target_id
			card_id
		}
	}
`

export const FETCH_TELERAMA_ACCESSES = gql`
	query FetchTeleramas($customerId: Int!) {
		telerama(customerId: $customerId)
	}
`

export const FETCH_COUNTRY = gql`
	query FetchCountry($isoCode: String!, $prod: Boolean!) {
		country(isoCode: $isoCode, prod: $prod) {
			isoCode
			name
			svod
			svod_from
			svod_white_list
			previous_acquisitions
			stripe_api_key
			total_films
			total_unpublished_films
			socialNetworks {
				slug
				url
			}
			subscriptions {
				key
				festival
				id
				subscriptionId
			}
		}
	}
`

export const FETCH_ADMIN_COUNTRIES = gql`
	query FetchCountries($prod: Boolean!) {
		countries(prod: $prod) {
			isoCode
			name
			default_language
			svod
			svod_from
			svod_white_list
			previous_acquisitions
			ip
			stripe_api_key
			total_films
			total_unpublished_films
			socialNetworks {
				slug
				url
			}
			subscriptions {
				key
				festival
				id
				subscriptionId
			}
		}
	}
`

export const FETCH_ADMIN_MAINTENANCE = gql`
	query FetchMaintenance{
		countriesMaintenance {
				isoCode
				name
				status
				error
		}
	}
`

export const FETCH_MAINTENANCE_COUNTRY = gql`
query Maintenance($country: String){
	countryMaintenance(country: $country) {
			isoCode
			status
			error
	}
}
`

export const FETCH_ADMIN_FESTIVAL = gql`
	query FetchAdminFestival($id: ID!) {
		festival(id: $id) {
			id
			admins
			lives {
				id
				name
				url
				up
			}
		}
	}
`

export const FETCH_MY_PLAYLISTS = gql`
	query Playlists($includeIds: [ID!], $start: Int, $page: Int $perPage: Int) {
		playlists(includeIds: $includeIds, start: $start, page: $page, perPage: $perPage) {
			pagination {
					page
					lastPage
					total
			}
			items {
				id
				name
				note
				public
				username
				products(page: 1, perPage: 150) {
					pagination {
						page
						lastPage
						total
					}
					items {
						id
						name
						images {
							id
							source
							type
						}
					}
				}
			}
		}
	}
`

export const FETCH_MY_PLAYLISTS_NAMES = gql`
	query PlaylistsNames($includeIds: [ID!]) {
		playlists(includeIds: $includeIds, page: 1, perPage: 100) {
			pagination {
				page
				lastPage
				total
			}
			items {
				id
				name
				products(page: 1, perPage: 150) {
					pagination {
						page
						lastPage
						total
					}
					items {
						id
					}
				}
			}
		}
	}
`

export const GIFTS_PDF = gql`
	query GiftsPdf($id: Int!) {
		giftsPdf(orderId: $id) {
			id
			target
			type
			filename
			content
		}
	}
`
