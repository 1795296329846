import {
	createContext,
	useContext,
	useState,
	useEffect
} from "react"
import Router from "next/router"

import { isBrowser } from "lib/tools"
import { isSubscriptionUrl } from "lib/subscription/is-subscription-url"

const defaultModalContextValues = {
	modals: [],
	active: null,
	show: (options) => null,
	hide: (callback) => null,
	back: (callback) => null,
}

const ModalContext = createContext(defaultModalContextValues)

export const ProvideModal = ({ children }) => {
	const modal = useProvideModal()

	return (
		<ModalContext.Provider value={modal}>
			{children}
		</ModalContext.Provider>
	)
}

export const useModal = () => {
	return useContext(ModalContext)
}

const useProvideModal = () => {
	const [modals, setModals] = useState([])

	const active = modals.length > 0 ? modals[modals.length - 1] : null

	const back = callback => {
		setModals(modals => {
			if (modals.length === 0) {
				return modals
			}

			const newModals = [...modals]
			newModals[newModals.length - 1].status = "hidden"

			if (newModals.length > 1) {
				newModals[newModals.length - 2].status = "visible"
			}

			return newModals
		})

		setTimeout(
			() => {
				setModals(modals => {
					if (modals.length === 0) {
						return modals
					}

					const newModals = [...modals]
					newModals.pop()

					return newModals
				})

				if (callback) {
					callback()
				}
			},
			250
		)
	}

	const hide = callback => {
		setModals(modals => {
			if (modals.length === 0) {
				return modals
			}

			const newModals = [...modals]
			newModals[newModals.length - 1].status = "hidden"

			return newModals
		})

		setTimeout(
			() => {
				setModals(modals => {
					if (modals.length === 0) {
						return modals
					}

					return []
				})

				if (callback) {
					callback()
				}
			},
			250
		)
	}

	const show = (options) => {
		// Add modal
		setModals(modals => {
			const activeIndex = modals.findIndex(modal => modal.options.id === options.id)

			if (activeIndex === -1) {
				return [
					...modals,
					{
						status: "hidden",
						options
					}
				]
			}

			const newModals = [...modals]

			newModals.push(
				newModals.splice(activeIndex, 1)[0]
			)

			return newModals
		})

		// Do animation
		setTimeout(
			() => {
				setModals(modals => {
					const newModals = [...modals]

					if (newModals.length === 0) {
						return newModals
					}

					newModals[newModals.length - 1].status = "visible"


					if (newModals.length > 1 && !newModals[newModals.length - 1].options.doNotEncapsulate) {
						newModals[newModals.length - 2].status = "hidden"
					}

					return newModals
				})
			},
			150
		)
	}

	useEffect(
		() => {
			if (!isBrowser) {
				return
			}

			document.body.classList.remove("overlayed")
			document.body.classList.remove("interactive")

			if (modals.length > 0) {
				const {
					className,
					doNotEncapsulate
				} = active.options

				const hasOverlayed = modals.some(modal => !modal.options.doNotEncapsulate)

				if (hasOverlayed || !doNotEncapsulate) {
					document.body.classList.add("overlayed")
					if (className && className.includes("interactive")) {
						document.body.classList.add("interactive")
					}
				}
			}
		},
		[active]
	)

	useEffect(
		() => {
			if (!active || active.options.role === "alertdialog") {
				return
			}

			const handleChange = url => {
				if (
					isSubscriptionUrl(Router)
					|| active.options.disableHideOnRouteChange
				) {
					return
				}

				hide()
			}

			Router.events.on("routeChangeComplete", handleChange)

			return () => {
				Router.events.off("routeChangeComplete", handleChange)
			}
		},
		[active]
	)

	return {
		modals,
		active,
		show,
		hide,
		back
	}
}
