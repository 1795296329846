import NextI18Next from "next-i18next"

const NextI18NextInstance = new NextI18Next({
	defaultLanguage: "fr",
	otherLanguages: ["en", "de"],
	fallbackLng: "fr",
})

export default NextI18NextInstance

export { default as withTranslation } from "./with-translation"
/* Optionally, export class methods as named exports */
export const {
  appWithTranslation,
	Link,
	i18n
} = NextI18NextInstance

