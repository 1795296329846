import { gql } from "apollo-boost"

export const UPLOAD_PRODUCT_COVER = gql`
	mutation UploadProductCover($productId: Int!, $file: Upload!) {
		cover(productId: $productId, file: $file) {
			id
		}
	}
`

export const CREATE_LIST = gql`
	mutation CreateList($directorId: Int, $directorName: String, $parentCategoryKey: String!) {
		list(directorId: $directorId, directorName: $directorName, parentCategoryKey: $parentCategoryKey) {
			key,
			foreignkey,
			custom
		}
	}
`

export const UPDATE_CATEGORY = gql`
	mutation UpdateCategory($id: Int!, $description: [I18NString], $description_short: [I18NString], $products: [Int], $baseProducts: [Int], $banner: Upload) {
		updateCategory(id: $id, description: $description, description_short: $description_short, products: $products, baseProducts: $baseProducts, banner: $banner) {
			key,
			foreignkey,
			custom
		}
	}
`

export const CREATE_DIRECTOR = gql`
	mutation CreateDirector($name: String!) {
		director(name: $name) {
			id,
			name,
			description { lang, langCode, value },
			description_short { lang, langCode, value },
			image
		}
	}
`

export const UPDATE_DIRECTOR = gql`
	mutation UpdateDirector($id: Int!, $name: String, $description: [I18NString], $description_short: [I18NString], $image: Upload) {
		updateDirector(id: $id, name: $name, description: $description, description_short: $description_short, image: $image) {
			id,
			name,
			description { lang, langCode, value },
			description_short { lang, langCode, value },
			image
		}
	}
`

export const CREATE_CATEGORY = gql`
	mutation CreateCategory($name: String!, $parentCategoryKey: String!) {
		category(name: $name, parentCategoryKey: $parentCategoryKey) {
			key,
			foreignkey,
			custom
		}
	}
`

export const UPDATE_PAGE = gql`
	mutation UpdatePage($route: String!, $title: [I18NString], $description: [I18NString]) {
		page(route: $route, title: $title, description: $description) {
			route,
			title{
				langCode,
				value
			},
			description{
				langCode,
				value
			}
		}
	}
`

export const DESTROY_DIRECTOR = gql`
	mutation DestroyDirector($id: Int!) {
		destroyDirector(id: $id)
	}
`

export const CREATE_PRODUCT = gql`
	mutation CreateProduct($name: String!) {
		product(name: $name) {
			id,
			name { lang, langCode, value },
			description { lang, langCode, value },
			description_short { lang, langCode, value },
			image
		}
	}
`

export const UPDATE_PRODUCT = gql`
	mutation UpdateProduct($id: Int!, $name: [I18NString], $description: [I18NString], $description_short: [I18NString], $year: Int, $origin: String, $classification: Int, $availability: Int, $duration: String, $original_title: String, $image: Upload, $files: [String], $baseFiles: [String], $directors: [Int], $baseDirectors: [Int]) {
		updateProduct(id: $id, name: $name, description: $description, description_short: $description_short, year: $year, origin: $origin, classification: $classification, availability: $availability, duration: $duration, original_title: $original_title, image: $image, files: $files, baseFiles: $baseFiles, directors: $directors, baseDirectors: $baseDirectors) {
			id,
			name { lang, langCode, value },
			description { lang, langCode, value },
			description_short { lang, langCode, value },
			image
		}
	}
`

export const DESTROY_PRODUCT = gql`
	mutation DestroyProduct($id: Int!) {
		destroyProduct(id: $id)
	}
`

export const SUBSCRIBE_TO_NEWSLETTER = gql`
	mutation Subscribe($email: String!, $country: String!) {
		subscribeToNewsletter(email: $email, country: $country, mailingListId: null)
	}
`

export const SUBSCRIBE_TO_CINECLUB = gql`
	mutation Subscribe($email: String!, $country: String!, $mailingListId: Int) {
		subscribeToNewsletter(email: $email, country: $country, mailingListId: $mailingListId)
	}
`

export const CREATE_CARD = gql`
	mutation createCard($token: String!, $customer: String!, $name: String!) {
		stripeSource(token: $token, customer: $customer, name: $name) {
			id
			brand
			exp_month
			exp_year
			last4
		}
	}
`

export const SPONSOR_MUTATION = gql`
	mutation Sponsor($data: SponsoringInput) {
    sendSponsors(data: $data) {
      result
    }
	}
`

export const CREATE_CARD_TOKEN = gql`
	mutation createCardToken($card: String!, $customer: String!) {
		cardToken(card: $card, customer: $customer) {
			id
		}
	}
`

export const REMOVE_CARD = gql`
	mutation removeCard($card: String!, $customer: String!) {
		removeCard(card: $card, customer: $customer)
	}
`

export const TOGGLE_FILM_NOTIFICATION = gql`
	mutation toggleFilmNotification($user: Int!, $film: Int!) {
		toggleFilmNotification(user: $user, film: $film)
	}
`

export const CREATE_RENEWAL = gql`
	mutation createRenewal($user_id: Int!, $country_isoCode: String!, $target_id: Int!, $renewal_date: String!, $card_id: String!) {
		createRenewal(user_id: $user_id, country_isoCode: $country_isoCode, subscription_id: $target_id, renewal_date: $renewal_date, card_id: $card_id) {
			user_id
			country_isoCode
			target_id
			renewal_date
		}
	}
`

export const CANCEL_RENEWAL = gql`
	mutation CancelRenewal($user_id: Int!, $target_id: Int!) {
		cancelRenewal(user_id: $user_id, target_id: $target_id)
	}
`

export const CONSUME_PROMO = gql`
	mutation ConsumePromo($code: String!, $userId: Int!) {
		consumePromo(code: $code, user_id: $userId) {
			type
			name
			id
			success
			message
		}
	}
`

export const MANAGE_TELERAMA = gql`
	mutation($data: TeleramaInput) {
    telerama(data: $data) {
      result
    	message
			accessToken
			refreshToken
			email
			productId
    }
	}
`

export const TOGGLE_FESTIVAL_LIVE = gql`
	mutation toggleLive($id: ID!) {
		toggle(id: $id) {
			id
			admins
			lives {
				id
				name
				url
				up
			}
		}
	}
`

export const CREATE_FESTIVAL_LIVE = gql`
	mutation createLive($festival_id: ID!, $name: String!, $url: String!) {
		createLive(festival_id: $festival_id, name: $name, url: $url) {
			id
			lives {
				id
				name
				url
				up
			}
		}
	}
`

export const UPDATE_FESTIVAL_LIVE = gql`
	mutation updateLive($id: ID!, $name: String!, $url: String!) {
		updateLive(id: $id, name: $name, url: $url) {
			id
			lives {
				id
				name
				url
				up
			}
		}
	}
`

export const DESTROY_FESTIVAL_LIVE = gql`
	mutation destroyLive($id: ID!) {
		destroyLive(id: $id) {
			id
			lives {
				id
				name
				url
				up
			}
		}
	}
`

export const CHANGE_MAINTENANCE_STATUS = gql`
mutation Maintenance($country: String, $maintenance: Boolean){
	maintenance(country: $country, maintenance: $maintenance) {
			message
	}
}
`

export const CREATE_PLAYLIST = gql`
mutation CreatePlaylist($playlist: CreatePlaylistInput!) {
	createPlaylist(playlist: $playlist) {
			id
			name
	}
}
`

export const PLAYLIST_TOGGLE_PRODUCT = gql`
mutation TogglePlaylistProduct($playlistId: ID!, $productId: ID!) {
	togglePlaylistProduct(playlistId: $playlistId, productId: $productId)
}
`

export const PLAYLIST_UPDATE = gql`
mutation MutatePlaylist($playlist: MutatePlaylistInput!) {
	mutatePlaylist(playlist: $playlist) {
			id
			name
	}
}
`

export const PLAYLIST_DELETE = gql`
mutation DeletePlaylist($id: ID!) {
	deletePlaylist(id: $id)
}
`

export const PLAYLIST_MOVE_PRODUCT = gql`
mutation MovePlaylistProduct($playlistId: ID!, $productId: ID!, $position: Int!) {
	movePlaylistProduct(playlistId: $playlistId, productId: $productId, position: $position)
}
`
