import { useTranslation as useRealTranslation } from "react-i18next"
import { styledContent } from "lib/tools/contents"

export const useTranslation = (namespace = "common") => {
	const { t } = useRealTranslation(namespace)
	const translate = (key, params) => { 
		let k = key ? key.replace(":", "---", true) : null
		let value = styledContent(t(k, params) ? t(k, params).replace(":", "---", true) : "")
		if (value && typeof value === "string") {
			value.replace("---", ":", true)
		}
		return value
	}
	return { c: translate, ec: translate }
}
