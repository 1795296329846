import { i18n } from "../i18n"

const redirects = [
	{ from: "/about", fr: "/a-propos", en: "/about", de: "/ueber-uns" },
	{ from: "/news", fr: "/nouveautes", en: "/currently", de: "/neuheiten" },
	{ from: "/offer", fr: "/offrir-lacinetek", en: "/offer-lacinetek", de: "/lacinetek-verschenken" },
	{ from: "/ways-to-watch", fr: "/nos-offres", en: "/ways-to-watch", de: "/unsere-angebote" },
	{ from: "/offline-player", fr: "/lecteur-externe", en: "/desktop-player", de: "/desktop-player" },

	{ from: "/profile", fr: "/mon-compte", en: "/my-account", de: "/mein-benutzerkonto" },
	{ from: "/profile/parameters", fr: "/mon-compte/mes-parametres", en: "/my-account/my-parameters", de: "/mein-benutzerkonto/meine-einstellungen" },
	{ from: "/profile/billing", fr: "/mon-compte/paiements", en: "/my-account/payments", de: "/mein-benutzerkonto/meine-zahlungsmittel" },
	{ from: "/profile/sponsor", fr: "/mon-compte/parrainage", en: "/my-account/sponsorship", de: "/mein-benutzerkonto/sponsoring" },
	{ from: "/profile/apply-code", fr: "/mon-compte/appliquer-code", en: "/my-account/apply-code", de: "/mein-benutzerkonto/gutschein-code-einloesen" },
	{ from: "/profile/playlists", fr: "/mon-compte/mes-playlists", en: "/my-account/my-playlists", de: "/mein-benutzerkonto/meine-playlists" },
	{ from: "/profile/playlists/[slug]", fr: "/mon-compte/mes-playlists/[slug]", en: "/my-account/my-playlists/[slug]", de: "/mein-benutzerkonto/meine-playlists/[slug]" },

	{ from: "/administration/maintenance", fr: "/administration/maintenance", en: "/administration/maintenance", de: "/administration/maintenance" },

	{ from: "/lists/[id]", fr: "/la-liste-de/[id]", en: "/director-list/[id]", de: "/listen-von/[id]"},

	{ from: "/top-lists", fr: "/top-des-listes", en: "/most-selected-films", de: "/toplisten"},
	{ from: "/top-directors", fr: "/top-realisateurs", en: "/most-selected-directors", de: "/top-regisseure" },
	{ from: "/top-directors-films", fr: "/top-films-par-realisateurs", en: "/directors-with-greatest-amount-of-films", de: "/top-filme-regisseur"},

	{ from: "/treasures", fr: "/tresors-caches", en: "/hidden-treasures", de: "/verborgene-schaetze"},
	{ from: "/treasures/[id]", fr: "/tresors-caches/[id]", en: "/hidden-treasures/[id]", de: "/verborgene-schaetze/[id]"},
	{ from: "/treasures/[id]/[collection]", fr: "/tresors-caches/[id]/[collection]", en: "/hidden-treasures/[id]/[collection]", de: "/verborgene-schaetze/[id]/[collection]"},

	{ from: "/subscription", fr: "/abonnement", en: "/subscription", de: "/abonnement" },
	{ from: "/subscription/[slug]", fr: "/abonnement/[slug]", en: "/subscription/[slug]", de: "/abonnement/[slug]" },
	{ from: "/ways-to-watch/film-by-film", fr: "/nos-offres/a-l-unite", en: "/ways-to-watch/film-by-film", de: "/unsere-angebote/einzeln" },
	{ from: "/ways-to-watch/subscription", fr: "/nos-offres/abonnement", en: "/ways-to-watch/subscription", de: "/unsere-angebote/abonnement" }
]

export function get(target) {
	return getWithLanguage(target, i18n.language)
}

export function getWithLanguage(target, current) {
	if (!target) return target
	if (target == "/[lang]") return ""

	const object = (typeof target === "object") ? target : false
	if (object) {
		target = target.pathname
	}
	const realTarget = redirects.find(redirect => Object.keys(redirect).find(key => redirect[key] == target.replace("/[lang]", "")))
	if (realTarget) {
		target = realTarget[current] || target.replace("/[lang]", "")
	} else {
		target = target.replace("/[lang]", "")
	}
	if (object) {
		object.pathname = target
		target = object
	}
	return target
}

export function getFrom(target) {
	if (!target) return target

	const object = (typeof target === "object") ? target : false
	if (object) {
		target = target.pathname
	}

	const realTarget = redirects.find(redirect => Object.keys(redirect).find(key => redirect[key] == target.replace("/[lang]", "")))
	if (realTarget) {
		target = realTarget.from
	}
	if (object) {
		object.pathname = target
		target = object
	}
	return target
}

export const sanitizeUrl = (url) => {
	if (!url.match(/(http)/) || !url.match(/(\/\/)/)) {
		return get(url)
	}

	if (!url.match(/www.lacinetek.com/)) {
		return url
	}

	const cleanUrl = url.split("/").reduce((accumulator, currentValue) => {
		if (currentValue !== "" && !currentValue.match(/(http)/) && !currentValue.match(/(lacinetek)/)) {
			accumulator.push(currentValue)
		}
		return accumulator
	}, [])

	return get(`/${cleanUrl.join("/")}`, i18n.language)
}

export const replace = require("./replace").default

export default redirects
