import {
	createContext,
	useContext,
	useState,
	useEffect
} from "react"

import Router from "next/router"

const toastContext = createContext({
	status: "",
	delay: 3,
	message: "",
	show: () => {}
})

export const ModalConsumer = toastContext.Consumer

export const ProvideToast = ({ children }) => {
	const toast = useProvideToast()

	return (
		<toastContext.Provider value={toast}>
			{children}
		</toastContext.Provider>
	)
}

export const useToast = () => {
	return useContext(toastContext)
}

const useProvideToast = () => {
	const [status, setStatus] = useState("hidden")
	const [toastData, setToastData] = useState({})

	useEffect(() => {
		Router.events.on("routeChangeComplete", (url) => {
			hide()
		})
	}, [])


	const show = (options) => {
		const { delay, message } = options
		const timer = delay ?? 3

		setToastData({ message })
		setTimeout(() => {
			setStatus("active")
		}, 150)
		setTimeout(() => {
			hide()
		}, timer * 1000)
	}

	const hide = () => {
		setStatus("")
	}

	return {
		status,
		show,
		hide,
		...toastData
	}
}
